// extracted by mini-css-extract-plugin
export var colLeft = "UserCloudIaasServerProject_Details__colLeft__nr1W9";
export var colRight = "UserCloudIaasServerProject_Details__colRight__G8ZkI";
export var column = "UserCloudIaasServerProject_Details__column__YldRi";
export var columnWrapper = "UserCloudIaasServerProject_Details__columnWrapper__o__hV";
export var descriptionContent = "UserCloudIaasServerProject_Details__descriptionContent__C8ms1";
export var descriptionHeading = "UserCloudIaasServerProject_Details__descriptionHeading__PneEQ";
export var flex = "UserCloudIaasServerProject_Details__flex__YbDP2";
export var flexColumn = "UserCloudIaasServerProject_Details__flexColumn__KSn03";
export var gap1 = "UserCloudIaasServerProject_Details__gap1__cW6fX";
export var gap2 = "UserCloudIaasServerProject_Details__gap2__G2KuB";
export var gap3 = "UserCloudIaasServerProject_Details__gap3__WZivp";
export var gap4 = "UserCloudIaasServerProject_Details__gap4__YkiM0";
export var gap5 = "UserCloudIaasServerProject_Details__gap5__nVOr3";
export var lastUpdated = "UserCloudIaasServerProject_Details__lastUpdated__yxHg0";
export var reviewStatusWarning = "UserCloudIaasServerProject_Details__reviewStatusWarning__vEfE_";
export var row = "UserCloudIaasServerProject_Details__row__QsZeY";
// extracted by mini-css-extract-plugin
export var cloudHeader = "UserCloudIaasServerProject__cloudHeader__pQfHA";
export var column = "UserCloudIaasServerProject__column__vzJZT";
export var container = "UserCloudIaasServerProject__container__wmhmu";
export var contentWrapper = "UserCloudIaasServerProject__contentWrapper__PYnnA";
export var flex = "UserCloudIaasServerProject__flex__HHQgQ";
export var flexColumn = "UserCloudIaasServerProject__flexColumn__GH3dv";
export var gap1 = "UserCloudIaasServerProject__gap1__snqdv";
export var gap2 = "UserCloudIaasServerProject__gap2__kMckq";
export var gap3 = "UserCloudIaasServerProject__gap3__xV5pm";
export var gap4 = "UserCloudIaasServerProject__gap4__c0LTc";
export var gap5 = "UserCloudIaasServerProject__gap5__mHYjy";
export var row = "UserCloudIaasServerProject__row__y5wYd";
export var tag = "UserCloudIaasServerProject__tag__mF8KZ";